<template>
<div>
  <v-btn fixed top style="left:50%;transform: translateX(-50%);" depressed color="primary">{{room_details.room_name}}</v-btn>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 1920 1440"
    style="enable-background: new 0 0 1920 1440"
    xml:space="preserve"
  >
    <g id="Background_for_Ballroom_with_Diamond_Vendor_xA0_Image_1_">
      <image
        style="overflow: visible"
        width="1920"
        height="1440"
        id="Background_for_Ballroom_with_Diamond_Vendor_xA0_Image"
        :xlink:href="asset + '555D6AB6.png'"
      ></image>
    </g>
    <g id="Booth_22_images_1_" v-if="booths.D5.assignment_id">
      <g id="Booth_22_Vacant_xA0_Image_1_">
        <image
          style="overflow: visible"
          width="264"
          height="180"
          id="Booth_22_Vacant_xA0_Image"
          :xlink:href="small_image_asset + 'Booth 24 Vacant.png'"
          transform="matrix(1 0 0 1 1593 933)"
        ></image>
      </g>
      <g id="Booth_22_Basic_xA0_Image_1_">
        <image
          style="overflow: visible"
          width="264"
          height="180"
          id="Booth_22_Basic_xA0_Image"
          :xlink:href="getImage(24,booths.D5.vendor_details.sponsor_type)"
          transform="matrix(1 0 0 1 1593 933)"
        ></image>
      </g>
    </g>
    <g id="Booth_21_images_1_" v-if="booths.D4.assignment_id">
      <g id="Booth_21_Vacant_xA0_Image_1_">
        <image
          style="overflow: visible"
          width="264"
          height="170"
          id="Booth_21_Vacant_xA0_Image"
           :xlink:href="small_image_asset + 'Booth 23 Vacant.png'"
          transform="matrix(1 0 0 1 1272 884)"
        ></image>
      </g>
      <g id="Booth_21_Basic_xA0_Image_1_">
        <image
          style="overflow: visible"
          width="264"
          height="170"
          id="Booth_21_Basic_xA0_Image"
          :xlink:href="getImage(23,booths.D4.vendor_details.sponsor_type)"
          transform="matrix(1 0 0 1 1272 884)"
        ></image>
      </g>
    </g>
    <g id="Booth_20_images_1_" v-if="booths.D2.assignment_id">
      <g id="Booth_20_Vacant_xA0_Image_1_">
        <image
          style="overflow: visible"
          width="264"
          height="170"
          id="Booth_20_Vacant_xA0_Image"
          :xlink:href="small_image_asset + 'Booth 20 Vacant.png'"
          transform="matrix(1 0 0 1 384 884)"
        ></image>
      </g>
      <g id="Booth_20_Basic_xA0_Image_1_">
        <image
          style="overflow: visible"
          width="264"
          height="170"
          id="Booth_20_Basic_xA0_Image"
          :xlink:href="getImage(20,booths.D2.vendor_details.sponsor_type)"
          transform="matrix(1 0 0 1 384 884)"
        ></image>
      </g>
    </g>
    <g id="Booth_19_images_1_" v-if="booths.D1.assignment_id">
      <g id="Booth_19_Vacant_xA0_Image_1_">
        <image
          style="overflow: visible"
          width="264"
          height="180"
          id="Booth_19_Vacant_xA0_Image"
          :xlink:href="small_image_asset + 'Booth 19 Vacant.png'"
          transform="matrix(1 0 0 1 63 933)"
        ></image>
      </g>
      <g id="Booth_19_Basic_xA0_Image_1_">
        <image
          style="overflow: visible"
          width="264"
          height="180"
          id="Booth_19_Basic_xA0_Image"
          :xlink:href="getImage(19,booths.D1.vendor_details.sponsor_type)"
          transform="matrix(1 0 0 1 63 933)"
        ></image>
      </g>
    </g>
    <g id="Booth_18_images_1_" v-if="booths.C6.assignment_id">
      <g id="Booth_18_Vacant_xA0_Image_1_">
        <image
          style="overflow: visible"
          width="264"
          height="180"
          id="Booth_18_Vacant_xA0_Image"
           :xlink:href="small_image_asset + 'Booth 18 Vacant.png'"
          transform="matrix(1 0 0 1 1593 709)"
        ></image>
      </g>
      <g id="Booth_18_Basic_xA0_Image_1_">
        <image
          style="overflow: visible"
          width="264"
          height="180"
          id="Booth_18_Basic_xA0_Image"
           :xlink:href="getImage(18,booths.C6.vendor_details.sponsor_type)"
          transform="matrix(1 0 0 1 1593 709)"
        ></image>
      </g>
    </g>
    <g id="Booth_17_images_1_" v-if="booths.C5.assignment_id">
      <g id="Booth_17_Vacant_xA0_Image_1_">
        <image
          style="overflow: visible"
          width="264"
          height="170"
          id="Booth_17_Vacant_xA0_Image"
          :xlink:href="small_image_asset + 'Booth 17 Vacant.png'"
          transform="matrix(1 0 0 1 1272 694)"
        ></image>
      </g>
      <g id="Booth_17_Basic_xA0_Image_1_">
        <image
          style="overflow: visible"
          width="264"
          height="170"
          id="Booth_17_Basic_xA0_Image"
          :xlink:href="getImage(17,booths.C5.vendor_details.sponsor_type)"
          transform="matrix(1 0 0 1 1272 694)"
        ></image>
      </g>
    </g>
    <g id="Booth_16_images_1_" v-if="booths.C4.assignment_id">
      <g id="Booth_16_Vacant_xA0_Image_1_">
        <image
          style="overflow: visible"
          width="264"
          height="160"
          id="Booth_16_Vacant_xA0_Image"
          :xlink:href="small_image_asset + 'Booth 16 Vacant.png'"
          transform="matrix(1 0 0 1 972 693)"
        ></image>
      </g>
      <g id="Booth_16_Basic_xA0_Image_1_">
        <image
          style="overflow: visible"
          width="264"
          height="160"
          id="Booth_16_Basic_xA0_Image"
           :xlink:href="getImage(16,booths.C4.vendor_details.sponsor_type)"
          transform="matrix(1 0 0 1 972 693)"
        ></image>
      </g>
    </g>
    <g id="Booth_15_images_1_" v-if="booths.C3.assignment_id">
      <g id="Booth_15_Vacant_xA0_Image_1_">
        <image
          style="overflow: visible"
          width="264"
          height="160"
          id="Booth_15_Vacant_xA0_Image"
           :xlink:href="small_image_asset + 'Booth 15 Vacant.png'"
          transform="matrix(1 0 0 1 684 693)"
        ></image>
      </g>
      <g id="Booth_15_Basic_xA0_Image_1_">
        <image
          style="overflow: visible"
          width="264"
          height="160"
          id="Booth_15_Basic_xA0_Image"
           :xlink:href="getImage(15,booths.C3.vendor_details.sponsor_type)"
          transform="matrix(1 0 0 1 684 693)"
        ></image>
      </g>
    </g>
    <g id="Booth_14_images_1_" v-if="booths.C2.assignment_id">
      <g id="Booth_14_Vacant_xA0_Image_1_">
        <image
          style="overflow: visible"
          width="264"
          height="170"
          id="Booth_14_Vacant_xA0_Image"
          :xlink:href="small_image_asset + 'Booth 14 Vacant.png'"
          transform="matrix(1 0 0 1 384 694)"
        ></image>
      </g>
      <g id="Booth_14_Basic_xA0_Image_1_">
        <image
          style="overflow: visible"
          width="264"
          height="170"
          id="Booth_14_Basic_xA0_Image"
           :xlink:href="getImage(14,booths.C2.vendor_details.sponsor_type)"
          transform="matrix(1 0 0 1 384 694)"
        ></image>
      </g>
    </g>
    <g id="Booth_13_images_1_" v-if="booths.C1.assignment_id">
      <g id="Booth_13_Vacant_xA0_Image_1_">
        <image
          style="overflow: visible"
          width="264"
          height="180"
          id="Booth_13_Vacant_xA0_Image"
           :xlink:href="small_image_asset + 'Booth 13 Vacant.png'"
          transform="matrix(1 0 0 1 63 709)"
        ></image>
      </g>
      <g id="Booth_13_Basic_xA0_Image_1_">
        <image
          style="overflow: visible"
          width="264"
          height="180"
          id="Booth_13_Basic_xA0_Image"
           :xlink:href="getImage(13,booths.C1.vendor_details.sponsor_type)"
          transform="matrix(1 0 0 1 63 709)"
        ></image>
      </g>
    </g>
    <g id="Booth_12_images_1_" v-if="booths.B6.assignment_id">
      <g id="Booth_12_Vacant_xA0_Image_1_">
        <image
          style="overflow: visible"
          width="264"
          height="180"
          id="Booth_12_Vacant_xA0_Image"
          :xlink:href="small_image_asset + 'Booth 12 Vacant.png'"
          transform="matrix(1 0 0 1 1593 488)"
        ></image>
      </g>
      <g id="Booth_12_Basic_xA0_Image_1_">
        <image
          style="overflow: visible"
          width="264"
          height="180"
          id="Booth_12_Basic_xA0_Image"
          :xlink:href="getImage(12,booths.B6.vendor_details.sponsor_type)"
          transform="matrix(1 0 0 1 1593 488)"
        ></image>
      </g>
    </g>
    <g id="Booth_11_images_1_" v-if="booths.B5.assignment_id">
      <g id="Booth_11_Vacant_xA0_Image_1_">
        <image
          style="overflow: visible"
          width="264"
          height="170"
          id="Booth_11_Vacant_xA0_Image"
           :xlink:href="small_image_asset + 'Booth 11 Vacant.png'"
          transform="matrix(1 0 0 1 1272 505)"
        ></image>
      </g>
      <g id="Booth_11_Basic_xA0_Image_1_">
        <image
          style="overflow: visible"
          width="264"
          height="170"
          id="Booth_11_Basic_xA0_Image"
          :xlink:href="getImage(11,booths.B5.vendor_details.sponsor_type)"
          transform="matrix(1 0 0 1 1272 505)"
        ></image>
      </g>
    </g>
    <g id="Booth_10_images_1_" v-if="booths.B4.assignment_id">
      <g id="Booth_10_Vacant_xA0_Image_1_">
        <image
          style="overflow: visible"
          width="264"
          height="160"
          id="Booth_10_Vacant_xA0_Image"
          :xlink:href="small_image_asset + 'Booth 10 Vacant.png'"
          transform="matrix(1 0 0 1 972 518)"
        ></image>
      </g>
      <g id="Booth_10_Basic_xA0_Image_1_">
        <image
          style="overflow: visible"
          width="264"
          height="160"
          id="Booth_10_Basic_xA0_Image"
           :xlink:href="getImage(10,booths.B4.vendor_details.sponsor_type)"
          transform="matrix(1 0 0 1 972 518)"
        ></image>
      </g>
    </g>
    <g id="Booth_9_images_1_" v-if="booths.B3.assignment_id">
      <g id="Booth_9_Vacant_xA0_Image_1_">
        <image
          style="overflow: visible"
          width="264"
          height="160"
          id="Booth_9_Vacant_xA0_Image"
           :xlink:href="small_image_asset + 'Booth 9 Vacant.png'"
          transform="matrix(1 0 0 1 684 518)"
        ></image>
      </g>
      <g id="Booth_9_Basic_xA0_Image_1_">
        <image
          style="overflow: visible"
          width="264"
          height="160"
          id="Booth_9_Basic_xA0_Image"
           :xlink:href="getImage(9,booths.B3.vendor_details.sponsor_type)"
          transform="matrix(1 0 0 1 684 518)"
        ></image>
      </g>
    </g>
    <g id="Booth_8_images_1_" v-if="booths.B2.assignment_id">
      <g id="Booth_8_Vacant_xA0_Image_1_">
        <image
          style="overflow: visible"
          width="264"
          height="170"
          id="Booth_8_Vacant_xA0_Image"
           :xlink:href="small_image_asset + 'Booth 8 Vacant.png'"
          transform="matrix(1 0 0 1 384 505)"
        ></image>
      </g>
      <g id="Booth_8_Basic_xA0_Image_1_">
        <image
          style="overflow: visible"
          width="264"
          height="170"
          id="Booth_8_Basic_xA0_Image"
          :xlink:href="getImage(8,booths.B2.vendor_details.sponsor_type)"
          transform="matrix(1 0 0 1 384 505)"
        ></image>
      </g>
    </g>
    <g id="Booth_7_images_1_" v-if="booths.B1.assignment_id">
      <g id="Booth_7_Vacant_xA0_Image_1_">
        <image
          style="overflow: visible"
          width="264"
          height="180"
          id="Booth_7_Vacant_xA0_Image"
          :xlink:href="small_image_asset + 'Booth 7 Vacant.png'"
          transform="matrix(1 0 0 1 63 488)"
        ></image>
      </g>
      <g id="Booth_7_Basic_xA0_Image_1_">
        <image
          style="overflow: visible"
          width="264"
          height="180"
          id="Booth_7_Basic_xA0_Image"
          :xlink:href="getImage(7,booths.B1.vendor_details.sponsor_type)"
          transform="matrix(1 0 0 1 63 488)"
        ></image>
      </g>
    </g>
    <g id="Booth_6_images_1_" v-if="booths.A6.assignment_id">
      <g id="Booth_6_Vacant_xA0_Image_1_">
        <image
          style="overflow: visible"
          width="264"
          height="180"
          id="Booth_6_Vacant_xA0_Image"
          :xlink:href="small_image_asset + 'Booth 6 Vacant.png'"
          transform="matrix(1 0 0 1 1593 285)"
        ></image>
      </g>
      <g id="Booth_6_Basic_xA0_Image_1_">
        <image
          style="overflow: visible"
          width="264"
          height="180"
          id="Booth_6_Basic_xA0_Image"
          :xlink:href="getImage(6,booths.A6.vendor_details.sponsor_type)"
          transform="matrix(1 0 0 1 1593 285)"
        ></image>
      </g>
    </g>
    <g id="Booth_5_images_1_" v-if="booths.A5.assignment_id">
      <g id="Booth_5_Vacant_xA0_Image_1_">
        <image
          style="overflow: visible"
          width="264"
          height="170"
          id="Booth_5_Vacant_xA0_Image"
          :xlink:href="small_image_asset + 'Booth 5 Vacant.png'"
          transform="matrix(1 0 0 1 1272 328)"
        ></image>
      </g>
      <g id="Booth_5_Basic_xA0_Image_1_">
        <image
          style="overflow: visible"
          width="264"
          height="170"
          id="Booth_5_Basic_xA0_Image"
           :xlink:href="getImage(5,booths.A5.vendor_details.sponsor_type)"
          transform="matrix(1 0 0 1 1272 328)"
        ></image>
      </g>
    </g>
    <g id="Booth_4_images_1_" v-if="booths.A4.assignment_id">
      <g id="Booth_4_Vacant_xA0_Image_1_">
        <image
          style="overflow: visible"
          width="264"
          height="160"
          id="Booth_4_Vacant_xA0_Image"
         :xlink:href="small_image_asset + 'Booth 4 Vacant.png'"
          transform="matrix(1 0 0 1 972 341)"
        ></image>
      </g>
      <g id="Booth_4_Basic_xA0_Image_1_">
        <image
          style="overflow: visible"
          width="264"
          height="160"
          id="Booth_4_Basic_xA0_Image"
          :xlink:href="getImage(4,booths.A4.vendor_details.sponsor_type)"
          transform="matrix(1 0 0 1 972 341)"
        ></image>
      </g>
    </g>
    <g id="Booth_3_images_1_" v-if="booths.A3.assignment_id">
      <g id="Booth_3_Vacant_xA0_Image_1_">
        <image
          style="overflow: visible"
          width="264"
          height="160"
          id="Booth_3_Vacant_xA0_Image"
          :xlink:href="small_image_asset + 'Booth 3 Vacant.png'"
          transform="matrix(1 0 0 1 684 341)"
        ></image>
      </g>
      <g id="Booth_3_Basic_xA0_Image_1_">
        <image
          style="overflow: visible"
          width="264"
          height="160"
          id="Booth_3_Basic_xA0_Image"
         :xlink:href="getImage(3,booths.A3.vendor_details.sponsor_type)"
          transform="matrix(1 0 0 1 684 341)"
        ></image>
      </g>
    </g>
    <g id="Booth_2_images_1_" v-if="booths.A2.assignment_id">
      <g id="Booth_2_Vacant_xA0_Image_1_">
        <image
          style="overflow: visible"
          width="264"
          height="170"
          id="Booth_2_Vacant_xA0_Image"
          :xlink:href="small_image_asset + 'Booth 2 Vacant.png'"
          transform="matrix(1 0 0 1 384 328)"
        ></image>
      </g>
      <g id="Booth_2_Basic_xA0_Image_1_">
        <image
          style="overflow: visible"
          width="264"
          height="170"
          id="Booth_2_Basic_xA0_Image"
          :xlink:href="getImage(2,booths.A2.vendor_details.sponsor_type)"
          transform="matrix(1 0 0 1 384 328)"
        ></image>
      </g>
    </g>
    <g id="Booth_1_images_1_" v-if="booths.A1.assignment_id">
      <g
        id="Booth_1_Vacant_xA0_Image_1_"
        @click="openBoothDetails(booths.A1.vendor_id)"
        class="cursor_pointer"
        v-if="booths.A1.vendor_id"
      >
        <image
          style="overflow: visible"
          width="264"
          height="180"
          id="Booth_1_Vacant_xA0_Image"
          :xlink:href="small_image_asset + 'Booth 1 Vacant.png'"
          transform="matrix(1 0 0 1 63 285)"
        ></image>
      </g>
      <g id="Booth_1_Basic_xA0_Image_1_">
        <image
          style="overflow: visible"
          width="264"
          height="180"
          id="Booth_1_Basic_xA0_Image"
          :xlink:href="getImage(1,booths.A1.vendor_details.sponsor_type)"
          transform="matrix(1 0 0 1 63 285)"
        ></image>
      </g>

    </g>
    <g id="Group_1_1_">
      <g id="_x31__9_">
        <a href="javascript:;">
          <g
            id="Rectangle_1_2_"
            class="st0 cursor_pointer"
            @click="openBoothDetails(booths.A1.vendor_id)"
            v-if="booths.A1.vendor_id"
          >
            <defs>
              <rect
                id="SVGID_1_"
                x="63"
                y="285"
                class="st0"
                width="264"
                height="180"
              />
            </defs>
            <clipPath id="SVGID_2_">
              <use xlink:href="#SVGID_1_" style="overflow: visible" />
            </clipPath>

            <image
              style="overflow: visible; clip-path: url(#SVGID_2_)"
              width="1920"
              height="1440"
              id="Rectangle_1_xA0_Image_5_"
              :xlink:href="asset + 'layer_img.png'"
            ></image>
          </g>
        </a>
        <g
          id="Rectangle_1_copy_2_"
          class="st0 cursor_pointer"
          @click="openBoothDetails(booths.B1.vendor_id)"
          v-if="booths.B1.vendor_id"
        >
          <defs>
            <rect
              id="SVGID_3_"
              x="63"
              y="488"
              class="st0"
              width="264"
              height="180"
            />
          </defs>
          <clipPath id="SVGID_4_">
            <use xlink:href="#SVGID_3_" style="overflow: visible" />
          </clipPath>

          <image
            style="overflow: visible; clip-path: url(#SVGID_4_)"
            width="1920"
            height="1440"
            id="Rectangle_1_copy_xA0_Image_1_"
            :xlink:href="asset + 'layer_img.png'"
          ></image>
        </g>
        <g
          id="Rectangle_1_copy_2_2_"
          class="st0 cursor_pointer"
          @click="openBoothDetails(booths.C1.vendor_id)"
          v-if="booths.C1.vendor_id"
        >
          <defs>
            <rect
              id="SVGID_5_"
              x="63"
              y="709"
              class="st0"
              width="264"
              height="180"
            />
          </defs>
          <clipPath id="SVGID_6_">
            <use xlink:href="#SVGID_5_" style="overflow: visible" />
          </clipPath>

          <image
            style="overflow: visible; clip-path: url(#SVGID_6_)"
            width="1920"
            height="1440"
            id="Rectangle_1_copy_2_xA0_Image_1_"
            :xlink:href="asset + 'layer_img.png'"
          ></image>
        </g>
        <g
          id="Rectangle_1_copy_3_2_"
          class="st0 cursor_pointer"
          @click="openBoothDetails(booths.D1.vendor_id)"
          v-if="booths.D1.vendor_id"
        >
          <defs>
            <rect
              id="SVGID_7_"
              x="63"
              y="933"
              class="st0"
              width="264"
              height="180"
            />
          </defs>
          <clipPath id="SVGID_8_">
            <use xlink:href="#SVGID_7_" style="overflow: visible" />
          </clipPath>

          <image
            style="overflow: visible; clip-path: url(#SVGID_8_)"
            width="1920"
            height="1440"
            id="Rectangle_1_copy_3_xA0_Image_1_"
            :xlink:href="asset + 'layer_img.png'"
          ></image>
        </g>
      </g>
      <g id="_x32__9_">
        <g
          id="Rectangle_1_4_"
          class="st0 cursor_pointer"
          @click="openBoothDetails(booths.A2.vendor_id)"
          v-if="booths.A2.vendor_id"
        >
          <defs>
            <rect
              id="SVGID_9_"
              x="384"
              y="328"
              class="st0"
              width="264"
              height="170"
            />
          </defs>
          <clipPath id="SVGID_10_">
            <use xlink:href="#SVGID_9_" style="overflow: visible" />
          </clipPath>

          <image
            style="overflow: visible; clip-path: url(#SVGID_10_)"
            width="1920"
            height="1440"
            id="Rectangle_1_xA0_Image_4_"
            :xlink:href="asset + 'layer_img.png'"
          ></image>
        </g>
        <g
          id="Rectangle_1_copy_4_2_"
          class="st0 cursor_pointer"
          @click="openBoothDetails(booths.B2.vendor_id)"
          v-if="booths.B2.vendor_id"
        >
          <defs>
            <rect
              id="SVGID_11_"
              x="384"
              y="505"
              class="st0"
              width="264"
              height="170"
            />
          </defs>
          <clipPath id="SVGID_12_">
            <use xlink:href="#SVGID_11_" style="overflow: visible" />
          </clipPath>

          <image
            style="overflow: visible; clip-path: url(#SVGID_12_)"
            width="1920"
            height="1440"
            id="Rectangle_1_copy_4_xA0_Image_1_"
            :xlink:href="asset + 'layer_img.png'"
          ></image>
        </g>
        <g
          id="Rectangle_1_copy_5_2_"
          class="st0 cursor_pointer"
          @click="openBoothDetails(booths.C2.vendor_id)"
          v-if="booths.C2.vendor_id"
        >
          <defs>
            <rect
              id="SVGID_13_"
              x="384"
              y="694"
              class="st0"
              width="264"
              height="170"
            />
          </defs>
          <clipPath id="SVGID_14_">
            <use xlink:href="#SVGID_13_" style="overflow: visible" />
          </clipPath>

          <image
            style="overflow: visible; clip-path: url(#SVGID_14_)"
            width="1920"
            height="1440"
            id="Rectangle_1_copy_5_xA0_Image_1_"
            :xlink:href="asset + 'layer_img.png'"
          ></image>
        </g>
        <g
          id="Rectangle_1_copy_6_2_"
          class="st0 cursor_pointer"
          @click="openBoothDetails(booths.D2.vendor_id)"
          v-if="booths.D2.vendor_id"
        >
          <defs>
            <rect
              id="SVGID_15_"
              x="384"
              y="884"
              class="st0"
              width="264"
              height="170"
            />
          </defs>
          <clipPath id="SVGID_16_">
            <use xlink:href="#SVGID_15_" style="overflow: visible" />
          </clipPath>

          <image
            style="overflow: visible; clip-path: url(#SVGID_16_)"
            width="1920"
            height="1440"
            id="Rectangle_1_copy_6_xA0_Image_1_"
            :xlink:href="asset + 'layer_img.png'"
          ></image>
        </g>
      </g>
      <g id="_x33__9_">
        <g
          id="Rectangle_1_6_"
          class="st0 cursor_pointer"
          @click="openBoothDetails(booths.A3.vendor_id)"
          v-if="booths.A3.vendor_id"
        >
          <defs>
            <rect
              id="SVGID_17_"
              x="684"
              y="341"
              class="st0"
              width="264"
              height="160"
            />
          </defs>
          <clipPath id="SVGID_18_">
            <use xlink:href="#SVGID_17_" style="overflow: visible" />
          </clipPath>

          <image
            style="overflow: visible; clip-path: url(#SVGID_18_)"
            width="1920"
            height="1440"
            id="Rectangle_1_xA0_Image_3_"
            :xlink:href="asset + 'layer_img.png'"
          ></image>
        </g>
        <g
          id="Rectangle_1_copy_7_2_"
          class="st0 cursor_pointer"
          @click="openBoothDetails(booths.B3.vendor_id)"
          v-if="booths.B3.vendor_id"
        >
          <defs>
            <rect
              id="SVGID_19_"
              x="684"
              y="518"
              class="st0"
              width="264"
              height="160"
            />
          </defs>
          <clipPath id="SVGID_20_">
            <use xlink:href="#SVGID_19_" style="overflow: visible" />
          </clipPath>

          <image
            style="overflow: visible; clip-path: url(#SVGID_20_)"
            width="1920"
            height="1440"
            id="Rectangle_1_copy_7_xA0_Image_1_"
            :xlink:href="asset + 'layer_img.png'"
          ></image>
        </g>
        <g
          id="Rectangle_1_copy_8_2_"
          class="st0 cursor_pointer"
          @click="openBoothDetails(booths.C3.vendor_id)"
          v-if="booths.C3.vendor_id"
        >
          <defs>
            <rect
              id="SVGID_21_"
              x="684"
              y="693"
              class="st0"
              width="264"
              height="160"
            />
          </defs>
          <clipPath id="SVGID_22_">
            <use xlink:href="#SVGID_21_" style="overflow: visible" />
          </clipPath>

          <image
            style="overflow: visible; clip-path: url(#SVGID_22_)"
            width="1920"
            height="1440"
            id="Rectangle_1_copy_8_xA0_Image_1_"
            :xlink:href="asset + 'layer_img.png'"
          ></image>
        </g>
      </g>
      <g id="_x34__7_">
        <g
          id="Rectangle_1_8_"
          class="st0 cursor_pointer"
          @click="openBoothDetails(booths.A4.vendor_id)"
          v-if="booths.A4.vendor_id"
        >
          <defs>
            <rect
              id="SVGID_23_"
              x="972"
              y="341"
              class="st0"
              width="264"
              height="160"
            />
          </defs>
          <clipPath id="SVGID_24_">
            <use xlink:href="#SVGID_23_" style="overflow: visible" />
          </clipPath>

          <image
            style="overflow: visible; clip-path: url(#SVGID_24_)"
            width="1920"
            height="1440"
            id="Rectangle_1_xA0_Image_2_"
            :xlink:href="asset + 'layer_img.png'"
          ></image>
        </g>
        <g
          id="Rectangle_1_copy_7_4_"
          class="st0 cursor_pointer"
          @click="openBoothDetails(booths.B4.vendor_id)"
          v-if="booths.B4.vendor_id"
        >
          <defs>
            <rect
              id="SVGID_25_"
              x="972"
              y="518"
              class="st0"
              width="264"
              height="160"
            />
          </defs>
          <clipPath id="SVGID_26_">
            <use xlink:href="#SVGID_25_" style="overflow: visible" />
          </clipPath>

          <image
            style="overflow: visible; clip-path: url(#SVGID_26_)"
            width="1920"
            height="1440"
            id="Rectangle_1_copy_7_xA0_Image"
            :xlink:href="asset + 'layer_img.png'"
          ></image>
        </g>
        <g
          id="Rectangle_1_copy_8_4_"
          class="st0 cursor_pointer"
          @click="openBoothDetails(booths.C4.vendor_id)"
          v-if="booths.C4.vendor_id"
        >
          <defs>
            <rect
              id="SVGID_27_"
              x="972"
              y="693"
              class="st0"
              width="264"
              height="160"
            />
          </defs>
          <clipPath id="SVGID_28_">
            <use xlink:href="#SVGID_27_" style="overflow: visible" />
          </clipPath>

          <image
            style="overflow: visible; clip-path: url(#SVGID_28_)"
            width="1920"
            height="1440"
            id="Rectangle_1_copy_8_xA0_Image"
            :xlink:href="asset + 'layer_img.png'"
          ></image>
        </g>
      </g>
      <g id="_x35__9_">
        <g
          id="Rectangle_1_10_"
          class="st0 cursor_pointer"
          @click="openBoothDetails(booths.A5.vendor_id)"
          v-if="booths.A5.vendor_id"
        >
          <defs>
            <rect
              id="SVGID_29_"
              x="1272"
              y="328"
              class="st0"
              width="264"
              height="170"
            />
          </defs>
          <clipPath id="SVGID_30_">
            <use xlink:href="#SVGID_29_" style="overflow: visible" />
          </clipPath>

          <image
            style="overflow: visible; clip-path: url(#SVGID_30_)"
            width="1920"
            height="1440"
            id="Rectangle_1_xA0_Image_1_"
            :xlink:href="asset + 'layer_img.png'"
          ></image>
        </g>
        <g
          id="Rectangle_1_copy_4_4_"
          class="st0 cursor_pointer"
          @click="openBoothDetails(booths.B5.vendor_id)"
          v-if="booths.B5.vendor_id"
        >
          <defs>
            <rect
              id="SVGID_31_"
              x="1272"
              y="505"
              class="st0"
              width="264"
              height="170"
            />
          </defs>
          <clipPath id="SVGID_32_">
            <use xlink:href="#SVGID_31_" style="overflow: visible" />
          </clipPath>

          <image
            style="overflow: visible; clip-path: url(#SVGID_32_)"
            width="1920"
            height="1440"
            id="Rectangle_1_copy_4_xA0_Image"
            :xlink:href="asset + 'layer_img.png'"
          ></image>
        </g>
        <g
          id="Rectangle_1_copy_5_4_"
          class="st0 cursor_pointer"
          @click="openBoothDetails(booths.C5.vendor_id)"
          v-if="booths.C5.vendor_id"
        >
          <defs>
            <rect
              id="SVGID_33_"
              x="1272"
              y="694"
              class="st0"
              width="264"
              height="170"
            />
          </defs>
          <clipPath id="SVGID_34_">
            <use xlink:href="#SVGID_33_" style="overflow: visible" />
          </clipPath>

          <image
            style="overflow: visible; clip-path: url(#SVGID_34_)"
            width="1920"
            height="1440"
            id="Rectangle_1_copy_5_xA0_Image"
            :xlink:href="asset + 'layer_img.png'"
          ></image>
        </g>
        <g
          id="Rectangle_1_copy_6_4_"
          class="st0 cursor_pointer"
          @click="openBoothDetails(booths.D4.vendor_id)"
          v-if="booths.D4.vendor_id"
        >
          <defs>
            <rect
              id="SVGID_35_"
              x="1272"
              y="884"
              class="st0"
              width="264"
              height="170"
            />
          </defs>
          <clipPath id="SVGID_36_">
            <use xlink:href="#SVGID_35_" style="overflow: visible" />
          </clipPath>

          <image
            style="overflow: visible; clip-path: url(#SVGID_36_)"
            width="1920"
            height="1440"
            id="Rectangle_1_copy_6_xA0_Image"
            :xlink:href="asset + 'layer_img.png'"
          ></image>
        </g>
      </g>
      <g id="_x36__9_">
        <g
          id="Rectangle_1_12_"
          class="st0 cursor_pointer"
          @click="openBoothDetails(booths.A6.vendor_id)"
          v-if="booths.A6.vendor_id"
        >
          <defs>
            <rect
              id="SVGID_37_"
              x="1593"
              y="285"
              class="st0"
              width="264"
              height="180"
            />
          </defs>
          <clipPath id="SVGID_38_">
            <use xlink:href="#SVGID_37_" style="overflow: visible" />
          </clipPath>

          <image
            style="overflow: visible; clip-path: url(#SVGID_38_)"
            width="1920"
            height="1440"
            id="Rectangle_1_xA0_Image"
            :xlink:href="asset + 'layer_img.png'"
          ></image>
        </g>
        <g
          id="Rectangle_1_copy_4_"
          class="st0 cursor_pointer"
          @click="openBoothDetails(booths.B6.vendor_id)"
          v-if="booths.B6.vendor_id"
        >
          <defs>
            <rect
              id="SVGID_39_"
              x="1593"
              y="488"
              class="st0"
              width="264"
              height="180"
            />
          </defs>
          <clipPath id="SVGID_40_">
            <use xlink:href="#SVGID_39_" style="overflow: visible" />
          </clipPath>

          <image
            style="overflow: visible; clip-path: url(#SVGID_40_)"
            width="1920"
            height="1440"
            id="Rectangle_1_copy_xA0_Image"
            :xlink:href="asset + 'layer_img.png'"
          ></image>
        </g>
        <g
          id="Rectangle_1_copy_2_4_"
          class="st0 cursor_pointer"
          @click="openBoothDetails(booths.C6.vendor_id)"
          v-if="booths.C6.vendor_id"
        >
          <defs>
            <rect
              id="SVGID_41_"
              x="1593"
              y="709"
              class="st0"
              width="264"
              height="180"
            />
          </defs>
          <clipPath id="SVGID_42_">
            <use xlink:href="#SVGID_41_" style="overflow: visible" />
          </clipPath>

          <image
            style="overflow: visible; clip-path: url(#SVGID_42_)"
            width="1920"
            height="1440"
            id="Rectangle_1_copy_2_xA0_Image"
            :xlink:href="asset + 'layer_img.png'"
          ></image>
        </g>
        <g
          id="Rectangle_1_copy_3_4_"
          class="st0 cursor_pointer"
          @click="openBoothDetails(booths.D5.vendor_id)"
          v-if="booths.D5.vendor_id"
        >
          <defs>
            <rect
              id="SVGID_43_"
              x="1593"
              y="933"
              class="st0"
              width="264"
              height="180"
            />
          </defs>
          <clipPath id="SVGID_44_">
            <use xlink:href="#SVGID_43_" style="overflow: visible" />
          </clipPath>

          <image
            style="overflow: visible; clip-path: url(#SVGID_44_)"
            width="1920"
            height="1440"
            id="Rectangle_1_copy_3_xA0_Image"
            :xlink:href="asset + 'layer_img.png'"
          ></image>
        </g>
      </g>
      <g id="_x37__1_" v-if="booths.D3.vendor_id">
        <a href="javascript:;" @click="openBoothDetails(booths.D3.vendor_id)">
          <g id="Rectangle_2_2_" class="st0">
            <defs>
              <rect
                id="SVGID_45_"
                x="684"
                y="874"
                class="st0"
                width="552"
                height="256"
              />
            </defs>
            <clipPath id="SVGID_46_">
              <use xlink:href="#SVGID_45_" style="overflow: visible" />
            </clipPath>

            <image
              style="overflow: visible; clip-path: url(#SVGID_46_)"
              width="1920"
              height="1440"
              id="Rectangle_2_xA0_Image"
              :xlink:href="asset + 'layer_img.png'"
            ></image>
          </g>
        </a>
      </g>
    </g>
    <g id="Logo_1_">
      <g
        id="_x31__8_"
        v-if="booths.A1.vendor_details.profile_pic"
        @click="openBoothDetails(booths.A1.vendor_id)"
        class="cursor_pointer"
      >
        <g id="Rectangle_4">
          <g>
            <rect x="95" y="290" class="st1" width="200" height="70" />
          </g>
        </g>
        <g id="DEMO_LOGO_copy_xA0_Image_43_">
          <image
            style="overflow: visible"
            width="200"
            height="70"
            id="DEMO_LOGO_copy_xA0_Image_42_"
            :xlink:href="booths.A1.vendor_details.profile_pic"
            transform="matrix(1 0 0 1 95 290)"
          ></image>
        </g>
      </g>
      <g
        id="_x32__8_"
        v-if="booths.A2.vendor_details.profile_pic"
        @click="openBoothDetails(booths.A2.vendor_id)"
        class="cursor_pointer"
      >
        <g id="Rectangle_4_1_">
          <g>
            <rect x="416" y="340" class="st1" width="200" height="70" />
          </g>
        </g>
        <g id="DEMO_LOGO_copy_xA0_Image_41_">
          <image
            style="overflow: visible"
            width="200"
            height="70"
            id="DEMO_LOGO_copy_xA0_Image_40_"
            :xlink:href="booths.A2.vendor_details.profile_pic"
            transform="matrix(1 0 0 1 416 340)"
          ></image>
        </g>
      </g>
      <g
        id="_x33__8_"
        v-if="booths.A3.vendor_details.profile_pic"
        @click="openBoothDetails(booths.A3.vendor_id)"
        class="cursor_pointer"
      >
        <g id="Rectangle_4_2_">
          <g>
            <rect x="716" y="360" class="st1" width="200" height="70" />
          </g>
        </g>
        <g id="DEMO_LOGO_copy_xA0_Image_39_">
          <image
            style="overflow: visible"
            width="200"
            height="70"
            id="DEMO_LOGO_copy_xA0_Image_38_"
            :xlink:href="booths.A3.vendor_details.profile_pic"
            transform="matrix(1 0 0 1 716 360)"
          ></image>
        </g>
      </g>
      <g
        id="_x34__6_"
        v-if="booths.A4.vendor_details.profile_pic"
        @click="openBoothDetails(booths.A4.vendor_id)"
        class="cursor_pointer"
      >
        <g id="Rectangle_4_3_">
          <g>
            <rect x="1004" y="360" class="st1" width="200" height="70" />
          </g>
        </g>
        <g id="DEMO_LOGO_copy_xA0_Image_37_">
          <image
            style="overflow: visible"
            width="200"
            height="70"
            id="DEMO_LOGO_copy_xA0_Image_36_"
            :xlink:href="booths.A4.vendor_details.profile_pic"
            transform="matrix(1 0 0 1 1004 360)"
          ></image>
        </g>
      </g>
      <g
        id="_x35__8_"
        v-if="booths.A5.vendor_details.profile_pic"
        @click="openBoothDetails(booths.A5.vendor_id)"
        class="cursor_pointer"
      >
        <g id="Rectangle_4_4_">
          <g>
            <rect x="1304" y="346" class="st1" width="200" height="70" />
          </g>
        </g>
        <g id="DEMO_LOGO_copy_xA0_Image_35_">
          <image
            style="overflow: visible"
            width="200"
            height="70"
            id="DEMO_LOGO_copy_xA0_Image_34_"
            :xlink:href="booths.A5.vendor_details.profile_pic"
            transform="matrix(1 0 0 1 1304 346)"
          ></image>
        </g>
      </g>
      <g
        id="_x36__8_"
        v-if="booths.A6.vendor_details.profile_pic"
        @click="openBoothDetails(booths.A6.vendor_id)"
        class="cursor_pointer"
      >
        <g id="Rectangle_4_5_">
          <g>
            <rect x="1612" y="302" class="st1" width="200" height="70" />
          </g>
        </g>
        <g id="DEMO_LOGO_copy_xA0_Image_33_">
          <image
            style="overflow: visible"
            width="200"
            height="70"
            id="DEMO_LOGO_copy_xA0_Image_32_"
            :xlink:href="booths.A6.vendor_details.profile_pic"
            transform="matrix(1 0 0 1 1612 302)"
          ></image>
        </g>
      </g>
    </g>
    <g id="Logo_2_1_">
      <g
        id="_x31__7_"
        v-if="booths.B1.vendor_details.profile_pic"
        @click="openBoothDetails(booths.B1.vendor_id)"
        class="cursor_pointer"
      >
        <g id="Rectangle_4_6_">
          <g>
            <rect x="95" y="505" class="st1" width="200" height="70" />
          </g>
        </g>
        <g id="DEMO_LOGO_copy_xA0_Image_31_">
          <image
            style="overflow: visible"
            width="200"
            height="70"
            id="DEMO_LOGO_copy_xA0_Image_30_"
            :xlink:href="booths.B1.vendor_details.profile_pic"
            transform="matrix(1 0 0 1 95 505)"
          ></image>
        </g>
      </g>
      <g
        id="_x32__7_"
        v-if="booths.B2.vendor_details.profile_pic"
        @click="openBoothDetails(booths.B2.vendor_id)"
        class="cursor_pointer"
      >
        <g id="Rectangle_4_7_">
          <g>
            <rect x="416" y="525" class="st1" width="200" height="70" />
          </g>
        </g>
        <g id="DEMO_LOGO_copy_xA0_Image_29_">
          <image
            style="overflow: visible"
            width="200"
            height="70"
            id="DEMO_LOGO_copy_xA0_Image_28_"
            :xlink:href="booths.B2.vendor_details.profile_pic"
            transform="matrix(1 0 0 1 416 525)"
          ></image>
        </g>
      </g>
      <g
        id="_x33__7_"
        v-if="booths.B3.vendor_details.profile_pic"
        @click="openBoothDetails(booths.B3.vendor_id)"
        class="cursor_pointer"
      >
        <g id="Rectangle_4_8_">
          <g>
            <rect x="716" y="532" class="st1" width="200" height="70" />
          </g>
        </g>
        <g id="DEMO_LOGO_copy_xA0_Image_27_">
          <image
            style="overflow: visible"
            width="200"
            height="70"
            id="DEMO_LOGO_copy_xA0_Image_26_"
            :xlink:href="booths.B3.vendor_details.profile_pic"
            transform="matrix(1 0 0 1 716 532)"
          ></image>
        </g>
      </g>
      <g
        id="_x34__5_"
        v-if="booths.B4.vendor_details.profile_pic"
        @click="openBoothDetails(booths.B4.vendor_id)"
        class="cursor_pointer"
      >
        <g id="Rectangle_4_9_">
          <g>
            <rect x="1004" y="532" class="st1" width="200" height="70" />
          </g>
        </g>
        <g id="DEMO_LOGO_copy_xA0_Image_25_">
          <image
            style="overflow: visible"
            width="200"
            height="70"
            id="DEMO_LOGO_copy_xA0_Image_24_"
            :xlink:href="booths.B4.vendor_details.profile_pic"
            transform="matrix(1 0 0 1 1004 532)"
          ></image>
        </g>
      </g>
      <g
        id="_x35__7_"
        v-if="booths.B5.vendor_details.profile_pic"
        @click="openBoothDetails(booths.B5.vendor_id)"
        class="cursor_pointer"
      >
        <g id="Rectangle_4_10_">
          <g>
            <rect x="1304" y="525" class="st1" width="200" height="70" />
          </g>
        </g>
        <g id="DEMO_LOGO_copy_xA0_Image_23_">
          <image
            style="overflow: visible"
            width="200"
            height="70"
            id="DEMO_LOGO_copy_xA0_Image_22_"
            :xlink:href="booths.B5.vendor_details.profile_pic"
            transform="matrix(1 0 0 1 1304 525)"
          ></image>
        </g>
      </g>
      <g
        id="_x36__7_"
        v-if="booths.B6.vendor_details.profile_pic"
        @click="openBoothDetails(booths.B6.vendor_id)"
        class="cursor_pointer"
      >
        <g id="Rectangle_4_11_">
          <g>
            <rect x="1610" y="508" class="st1" width="200" height="70" />
          </g>
        </g>
        <g id="DEMO_LOGO_copy_xA0_Image_21_">
          <image
            style="overflow: visible"
            width="200"
            height="70"
            id="DEMO_LOGO_copy_xA0_Image_20_"
            :xlink:href="booths.B6.vendor_details.profile_pic"
            transform="matrix(1 0 0 1 1610 508)"
          ></image>
        </g>
      </g>
    </g>
    <g id="Logo_3_1_">
      <g
        id="_x31__6_"
        v-if="booths.C1.vendor_details.profile_pic"
        @click="openBoothDetails(booths.C1.vendor_id)"
        class="cursor_pointer"
      >
        <g id="Rectangle_4_12_">
          <g>
            <rect x="95" y="720" class="st1" width="200" height="70" />
          </g>
        </g>
        <g id="DEMO_LOGO_copy_xA0_Image_19_">
          <image
            style="overflow: visible"
            width="200"
            height="70"
            id="DEMO_LOGO_copy_xA0_Image_18_"
            :xlink:href="booths.C1.vendor_details.profile_pic"
            transform="matrix(1 0 0 1 95 720)"
          ></image>
        </g>
      </g>
      <g
        id="_x32__6_"
        v-if="booths.C2.vendor_details.profile_pic"
        @click="openBoothDetails(booths.C2.vendor_id)"
        class="cursor_pointer"
      >
        <g id="Rectangle_4_13_">
          <g>
            <rect x="416" y="710" class="st1" width="200" height="70" />
          </g>
        </g>
        <g id="DEMO_LOGO_copy_xA0_Image_17_">
          <image
            style="overflow: visible"
            width="200"
            height="70"
            id="DEMO_LOGO_copy_xA0_Image_16_"
            :xlink:href="booths.C2.vendor_details.profile_pic"
            transform="matrix(1 0 0 1 416 710)"
          ></image>
        </g>
      </g>
      <g
        id="_x33__6_"
        v-if="booths.C3.vendor_details.profile_pic"
        @click="openBoothDetails(booths.C3.vendor_id)"
        class="cursor_pointer"
      >
        <g id="Rectangle_4_14_">
          <g>
            <rect x="716" y="705" class="st1" width="200" height="70" />
          </g>
        </g>
        <g id="DEMO_LOGO_copy_xA0_Image_15_">
          <image
            style="overflow: visible"
            width="200"
            height="70"
            id="DEMO_LOGO_copy_xA0_Image_14_"
            :xlink:href="booths.C3.vendor_details.profile_pic"
            transform="matrix(1 0 0 1 716 705)"
          ></image>
        </g>
      </g>
      <g
        id="_x34__4_"
        v-if="booths.C4.vendor_details.profile_pic"
        @click="openBoothDetails(booths.C4.vendor_id)"
        class="cursor_pointer"
      >
        <g id="Rectangle_4_15_">
          <g>
            <rect x="1004" y="705" class="st1" width="200" height="70" />
          </g>
        </g>
        <g id="DEMO_LOGO_copy_xA0_Image_13_">
          <image
            style="overflow: visible"
            width="200"
            height="70"
            id="DEMO_LOGO_copy_xA0_Image_12_"
            :xlink:href="booths.C4.vendor_details.profile_pic"
            transform="matrix(1 0 0 1 1004 705)"
          ></image>
        </g>
      </g>
      <g
        id="_x35__6_"
        v-if="booths.C5.vendor_details.profile_pic"
        @click="openBoothDetails(booths.C5.vendor_id)"
        class="cursor_pointer"
      >
        <g id="Rectangle_4_16_">
          <g>
            <rect x="1304" y="710" class="st1" width="200" height="70" />
          </g>
        </g>
        <g id="DEMO_LOGO_copy_xA0_Image_11_">
          <image
            style="overflow: visible"
            width="200"
            height="70"
            id="DEMO_LOGO_copy_xA0_Image_10_"
            :xlink:href="booths.C5.vendor_details.profile_pic"
            transform="matrix(1 0 0 1 1304 710)"
          ></image>
        </g>
      </g>
      <g
        id="_x36__6_"
        v-if="booths.C6.vendor_details.profile_pic"
        @click="openBoothDetails(booths.C6.vendor_id)"
        class="cursor_pointer"
      >
        <g id="Rectangle_4_17_">
          <g>
            <rect x="1614" y="715" class="st1" width="200" height="70" />
          </g>
        </g>
        <g id="DEMO_LOGO_copy_xA0_Image_9_">
          <image
            style="overflow: visible"
            width="200"
            height="70"
            id="DEMO_LOGO_copy_xA0_Image_8_"
            :xlink:href="booths.C6.vendor_details.profile_pic"
            transform="matrix(1 0 0 1 1614 715)"
          ></image>
        </g>
      </g>
    </g>
    <g id="Logo_3_copy_1_">
      <g
        id="_x31__5_"
        v-if="booths.D1.vendor_details.profile_pic"
        @click="openBoothDetails(booths.D1.vendor_id)"
        class="cursor_pointer"
      >
        <g id="Rectangle_4_18_">
          <g>
            <rect x="95" y="940" class="st1" width="200" height="70" />
          </g>
        </g>
        <g id="DEMO_LOGO_copy_xA0_Image_7_">
          <image
            style="overflow: visible"
            width="200"
            height="70"
            id="DEMO_LOGO_copy_xA0_Image_6_"
            :xlink:href="booths.D1.vendor_details.profile_pic"
            transform="matrix(1 0 0 1 95 940)"
          ></image>
        </g>
      </g>
      <g
        id="_x32__5_"
        v-if="booths.D2.vendor_details.profile_pic"
        @click="openBoothDetails(booths.D2.vendor_id)"
        class="cursor_pointer"
      >
        <g id="Rectangle_4_19_">
          <g>
            <rect x="416" y="897" class="st1" width="200" height="70" />
          </g>
        </g>
        <g id="DEMO_LOGO_copy_xA0_Image_5_">
          <image
            style="overflow: visible"
            width="200"
            height="70"
            id="DEMO_LOGO_copy_xA0_Image_4_"
            :xlink:href="booths.D2.vendor_details.profile_pic"
            transform="matrix(1 0 0 1 416 897)"
          ></image>
        </g>
      </g>
      <g
        id="_x33__5_"
        v-if="booths.D3.vendor_details.profile_pic"
        @click="openBoothDetails(booths.D3.vendor_id)"
        class="cursor_pointer"
      >
        <g id="Rectangle_4_20_">
          <g>
            <rect x="750" y="874" class="st1" width="410" height="70" />
          </g>
        </g>
        <g id="DEMO_LOGO_xA0_Image_1_">
          <image
            style="overflow: visible"
            width="500"
            height="120"
            id="DEMO_LOGO_xA0_Image"
            :xlink:href="booths.D3.vendor_details.profile_pic"
            transform="matrix(1 0 0 1 700 870)"
          ></image>
        </g>
      </g>
      <g
        id="_x35__5_"
        v-if="booths.D4.vendor_details.profile_pic"
        @click="openBoothDetails(booths.D4.vendor_id)"
        class="cursor_pointer"
      >
        <g id="Rectangle_4_21_">
          <g>
            <rect x="1304" y="890" class="st1" width="200" height="70" />
          </g>
        </g>
        <g id="DEMO_LOGO_copy_xA0_Image_3_">
          <image
            style="overflow: visible"
            width="200"
            height="70"
            id="DEMO_LOGO_copy_xA0_Image_2_"
            :xlink:href="booths.D4.vendor_details.profile_pic"
            transform="matrix(1 0 0 1 1304 890)"
          ></image>
        </g>
      </g>
      <g
        id="_x36__5_"
        v-if="booths.D5.vendor_details.profile_pic"
        @click="openBoothDetails(booths.D5.vendor_id)"
        class="cursor_pointer"
      >
        <g id="Rectangle_4_22_">
          <g>
            <rect x="1614" y="927" class="st1" width="200" height="70" />
          </g>
        </g>
        <g id="DEMO_LOGO_copy_xA0_Image_1_">
          <image
            style="overflow: visible"
            width="200"
            height="70"
            id="DEMO_LOGO_copy_xA0_Image"
            :xlink:href="booths.D5.vendor_details.profile_pic"
            transform="matrix(1 0 0 1 1614 927)"
          ></image>
        </g>
      </g>
    </g>
  </svg>
  <v-btn class="sticky-left-btn" small fab color="primary" :disabled="currentPage == 1" :to="'/vendor-hall/'+(currentPage-1)">
        <v-icon>mdi-arrow-left</v-icon>
    </v-btn>
    <v-btn class="sticky-right-btn" :disabled="currentPage == pages" small fab color="primary" :to="'/vendor-hall/'+(currentPage+1)">
        <v-icon>mdi-arrow-right</v-icon>
    </v-btn>
   <v-overlay :value="loading" absolute></v-overlay>
</div>
</template>
<script>
import axios from "@/helper/axios";
const default_booths = {
  A1: { vendor_details: { profile_pic: "" } },
  A2: { vendor_details: { profile_pic: "" } },
  A3: { vendor_details: { profile_pic: "" } },
  A4: { vendor_details: { profile_pic: "" } },
  A5: { vendor_details: { profile_pic: "" } },
  A6: { vendor_details: { profile_pic: "" } },
  B1: { vendor_details: { profile_pic: "" } },
  B2: { vendor_details: { profile_pic: "" } },
  B3: { vendor_details: { profile_pic: "" } },
  B4: { vendor_details: { profile_pic: "" } },
  B5: { vendor_details: { profile_pic: "" } },
  B6: { vendor_details: { profile_pic: "" } },
  C1: { vendor_details: { profile_pic: "" } },
  C2: { vendor_details: { profile_pic: "" } },
  C3: { vendor_details: { profile_pic: "" } },
  C4: { vendor_details: { profile_pic: "" } },
  C5: { vendor_details: { profile_pic: "" } },
  C6: { vendor_details: { profile_pic: "" } },
  D1: { vendor_details: { profile_pic: "" } },
  D2: { vendor_details: { profile_pic: "" } },
  D3: { vendor_details: { profile_pic: "" } },
  D4: { vendor_details: { profile_pic: "" } },
  D5: { vendor_details: { profile_pic: "" } },
};
export default {
  name: "VendorBoothImage",
  data() {
    return {
      loading: false,
      preview_data: null,
      show_preview: false,
      booths: default_booths,
      room_details : {},
      pages : 5
    };
  },
  watch: {
    "$route.params.room_id": function () {
      this.getBooths();
    },
  },
  computed: {
    asset() {
      return process.env.VUE_APP_ASSETS;
    },
    small_image_asset() {
      return process.env.VUE_APP_ASSETS + 'booth/vendor_hall/';
    },
    currentPage() {
      return Number(this.$route.params.room_id)
    }
  },
  methods: {
    clearBooth(){
      for(let key in this.booths){
        if(this.booths[key].assignment_id){
          this.booths[key].assignment_id = null
        }
        if(this.booths[key].vendor_id){
          this.booths[key].vendor_id = null
        }
        this.booths[key].vendor_details.profile_pic = null
      }
    },
    getBooths() {
      let _self = this;
      this.loading = true
      this.clearBooth()
      axios
        .get("/room/details?room_id=" + this.currentPage)
        .then(function (response) {
          // _self.object_list = response.data.data
          if(response.data.status){
             for (let key in response.data.data) {
              let data = response.data.data[key];
              _self.booths[data.booth_id] = data;
            }
            _self.room_details = response.data.room_detail
          }
        })
        .catch(function (e) {
          console.log(e);
        }).then(()=>{
          this.loading = false
        });
    },
    openBoothDetails(vendor_id) {
      if (vendor_id) {
        this.$router.push("/vendor/" + vendor_id);
      }
    },
    getImage(id,type){
      let path = ''
      switch(type) {
        case 3:
          path = this.small_image_asset + 'Booth '+id+' Basic.png'
        break;
        case 4:
          path = this.small_image_asset + 'Booth '+id+' Gold.png'
        break;
        case 5:
          path = this.small_image_asset + 'Booth '+id+' Diamond.png'
        break;
      }
      return path
    }
  },
  mounted() {
    this.getBooths();
  },
};
</script>
<style type="text/css">
.st0 {
  fill-rule: evenodd;
  clip-rule: evenodd;
}
.st1 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #0db04b;
}
.sticky-left-btn{
  position: fixed;
  top: 45%;
  left: 10px;
}
.sticky-right-btn{
  position: fixed;
  top: 45%;
  right: 10px;
}
</style>