<template>
  <v-dialog
    v-model="show_vendor_directory_modal"
    scrollable
    :max-width="maxPopupWidth"
    persistent
  >
    <v-card tile class="rounded-lg">
      <v-toolbar dark flat color="primary" dense>
        <v-toolbar-title>{{$t('common.vendor_directory_btn')}}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="closePopup">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider></v-divider>
      <v-card-subtitle class="pa-1">
        <v-row dense>
          <v-col cols="1" align-self="center" class="text-center">
            <b>{{$t('common.search_by')}}</b>
          </v-col>
          <v-col cols="4">
            <v-text-field
              outlined
              placeholder="Company name"
              hide-details
              dense
              append-icon="mdi-magnify"
              v-model.trim="search_vendor"
              v-debounce:300ms="searchVendor"
            ></v-text-field>
          </v-col>
          <v-col cols="3" align-self="center" class="text-right">
            <v-select
              v-model="selected_category"
              outlined
              dense
              :items="category_list"
              item-text="name"
              item-value="category_id"
              placeholder="Category"
              hide-details
              @change="setVendorCategory"
            ></v-select>
          </v-col>
        </v-row>
      </v-card-subtitle>
      <v-divider></v-divider>
      <v-card-text class="pa-0">
        <v-list class="pa-0" dense>
          <v-list-item v-for="(vendor, index) in vendor_list" :key="index" :to="'/vendor/'+vendor.vendor_id">
            <v-list-item-action>
              <v-img :src="vendor.profile_pic" contain height="100" max-width="100"></v-img>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ vendor.company_name }}</v-list-item-title>
              <v-list-item-subtitle>
                <span v-for="(levelOne,index) in vendor.categories" :key="levelOne.vendor_category_id">
                  <span v-for="category in levelOne.categories" :key="levelOne.vendor_category_id + category.category_id">
                    {{category.name}}<span v-if="index != Object.keys(vendor.categories).length - 1">,</span>
                  </span>
                </span>
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-img :src="vendor.sponsortype.sponsor_logo" max-width="70"></v-img>
            </v-list-item-action>
          </v-list-item>
        </v-list>
        <div v-if="vendor_list.length == 0" class="d-flex justify-center pa-2">
          <span>No vednor found</span>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState, mapActions, mapMutations } from "vuex";
import axios from "@/helper/axios";
export default {
  name: "VendorDirectory",
  data() {
    return {
      dialog: false,
      search_vendor: "",
      selected_category : null,
      items: [],
      category_list: []
    };
  },
  watch: {
    show_vendor_directory_modal(value) {
      if (value) {
        this.fetchVendor();
      }
    },
  },
  computed: {
    ...mapState("vendor_directory", [
      "show_vendor_directory_modal",
      "vendor_list",
    ]),
    ...mapState("utils", ["maxPopupWidth"]),
  },
  methods: {
    ...mapActions("vendor_directory", ["fetchVendor"]),
    ...mapMutations("vendor_directory", ["setVendorData"]),
    showVendorDirectory() {
      this.setVendorData({
        key: "show_vendor_directory_modal",
        data: true,
      });
    },
    searchVendor() {
      this.setVendorData({
        key: "search_vendor",
        data: this.search_vendor,
      });
      this.fetchVendor();
    },
    setVendorCategory() {
      this.setVendorData({
        key: "category_id",
        data: this.selected_category,
      });
      this.fetchVendor();
    },
    closePopup() {
      this.search_vendor = ""
      this.selected_category = ''
       this.setVendorData({
        key: "category_id",
        data: '',
      });
      this.setVendorData({
        key: "show_vendor_directory_modal",
        data: false,
      });
    },
    getCategoryList() {
      let _self = this;
      axios
        .get("organization/list")
        .then(function (response) {
          _self.category_list = response.data.data;
          _self.category_list.unshift({ name: "All", category_id: "" });
        })
        .catch(function (e) {
          console.log(e);
        });
    },
  },
  mounted: function () {
    this.fetchVendor();
    this.getCategoryList()
  },
  beforeDestroy(){
    this.closePopup()
    this.searchVendor()
  }
};
</script>